import { unpack, access, isPoint, toPoint, fromSimpleItem, isRuleName, isObjectId, isNumber, requestPaint, isInsideThePolygon } from './utility';
import Shapes from './utils/shapes';

export default class HumanDetectionAreaHandler {
  constructor(reference) {
    this.reference = reference;
  }

  handle($area) {
    this.reference.mHumanDetectionArea.forEach(e => e.destroyQmlObjects());
    this.reference.mHumanDetectionArea.splice(0, this.reference.mHumanDetectionArea.length);

    requestPaint();

    if (!$area) {
      return;
    }

    if ($area.Project === undefined) {
      return;
    }

    var project = new Shapes.Project($area.Project);

    if ($area.Fov === undefined) {
      return;
    }

    if ($area.Fov.VehicleFov !== undefined) {
      this.reference.mHumanDetectionArea.push(new Shapes.VehicleDetectionArea($area.Fov.VehicleFov.map(pt => new Shapes.Point(pt.x / 10000, pt.y / 10000))));
    }

    if ($area.Fov.Fov3D === undefined) {
      return;
    }

    var fov2dPointList = $area.Fov.Fov.map(function(e) {
      return { x: e.x / 10000, y: e.y / 10000 };
    });

    var fov3d = $area.Fov.Fov3D;

    var gridCentersOfGround = [];

    var maxDistanceFromCameraCenter = Math.ceil(fov3d.reduce(function(a, b) {
      return Math.max(a, Math.max(Math.abs(b.x), Math.abs(b.y)));
    }, 0) / 1000) * 1000;

    for (var cy = -maxDistanceFromCameraCenter; cy <= maxDistanceFromCameraCenter; cy += 1000) {
      for (var cx = -maxDistanceFromCameraCenter; cx <= maxDistanceFromCameraCenter; cx += 1000) {
        if (isInsideThePolygon({ x: cx, y: cy }, fov3d)) {
          var gridPointsList = [
            project.ProjectPoint({ x: cx - 500, y: cy - 500, z: 0 }),
            project.ProjectPoint({ x: cx + 500, y: cy - 500, z: 0 }),
            project.ProjectPoint({ x: cx + 500, y: cy + 500, z: 0 }),
            project.ProjectPoint({ x: cx - 500, y: cy + 500, z: 0 })
          ];
          if (gridPointsList.every(function(e) {
            return isInsideThePolygon(e, fov2dPointList);
          })) {
            gridCentersOfGround.push({
              gridPointsList: gridPointsList,
              raw: { x: cx, y: cy }
            });
          }
        }
      }
    }

    var toppestPointOfGrids = gridCentersOfGround.reduce(function(a, b) {
      return b.raw.y < a.y ? b.raw : a;
    }, { x: Number.POSITIVE_INFINITY, y: Number.POSITIVE_INFINITY });

    this.reference.mHumanDetectionArea.push(new Shapes.HumanDetectionArea(
      gridCentersOfGround.map(function(e) {
        return e.gridPointsList;
      }),
      this.reference.humanDetectionAreaManImg,
      project.ProjectPoint({ x: toppestPointOfGrids.x, y: toppestPointOfGrids.y, z: 0 }),
      project.ProjectPoint({ x: toppestPointOfGrids.x, y: toppestPointOfGrids.y, z: 1750 })));
  }
}
