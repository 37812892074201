import { unpack, access, isPoint, toPoint, fromSimpleItem, isRuleName, isObjectId, isNumber, requestPaint } from './utility';
import Shapes from './utils/shapes';

export default class RuleHandler {
  constructor(reference) {
    this.reference = reference;
  }

  handle(rule) {
    // orgin method
    for (var i = 0, j = this.reference.mExclusiveArea.length; i < j; i++) {
      this.reference.mExclusiveArea[i].destroyQmlObjects();
    }
    this.reference.mExclusiveArea.splice(0, this.reference.mExclusiveArea.length);
    for (i = 0, j = this.reference.mShapeList.length; i < j; i++) {
      this.reference.mShapeList[i].destroyQmlObjects();
    }
    this.reference.mShapeList.splice(0, this.reference.mShapeList.length);
    if (this.reference.mSummary !== undefined) {
      this.reference.mSummary.destroyQmlObjects();
      this.reference.mSummary = undefined;
    }
    this.reference.mMotionDetectionAlerts = true;

    requestPaint();

    if (!rule) {
      return;
    }
    this.handleRule(rule);
  }

  unpackRule($rule) {
    var envEnvelope = $rule["env:Envelope"];
    return envEnvelope !== undefined ? unpack(access(envEnvelope["env:Body"], "tan:GetRulesResponse", "tan:Rule")).map(function(tanRule) {
      var rule = {
        "@Type": tanRule["@Type"],
        "@Name": tanRule["@Name"]
      };
      var ttElementItem = access(tanRule["tt:Parameters"], "tt:ElementItem");
      if (ttElementItem !== undefined) {
        for (var prop in ttElementItem) {
          switch (prop)
          {
          case "tt:Polyline":
            rule.Polyline = { Point: ttElementItem[prop]["tt:Point"] };
            break;
          case "tt:Polygon":
            rule.Polygon = unpack(ttElementItem[prop]).map(function(ttPolygon) {
              return { Point: ttPolygon["tt:Point"] };
            });
            break;
          case "tt:PolylineArray":
            rule.PolylineArray = {
              Segment: unpack(ttElementItem[prop]["tt:Segment"]).map(function(ttSegment) {
                return { Point: ttSegment["tt:Point"] };
              })
            };
            break;
          }
        }
      }
      unpack(access(tanRule["tt:Parameters"], "tt:SimpleItem")).forEach(function(ttSimpleItem) {
        if (typeof ttSimpleItem["@Name"] === "string") {
          rule[ttSimpleItem["@Name"]] = ttSimpleItem["@Value"];
        }
      });
      return rule;
    }) : unpack(access(rule.Rules, "Rule"));
  }

  handleRule($rule) {
    this.unpackRule($rule).forEach(function(rule) {
      switch (rule["@Type"])
      {
      case "LineDetection":
        if (typeof rule["@Name"] === "string") {
          var points = unpack(access(rule.Polyline, "Point")).filter(isPoint).map(toPoint);
          if (Shapes.isPolyline(points)) {
            this.reference.mShapeList.push(new Shapes.LineDetection(this.reference.canvas, rule["@Name"], points, rule.Direction));
          }
        }
        break;
      case "FieldDetection":
      case "LoiteringDetection":
        if (typeof rule["@Name"] === "string") {
          var points = unpack(access(rule.Polygon, "Point")).filter(isPoint).map(toPoint);
          if (Shapes.isPolygon(points)) {
            this.reference.mShapeList.push(new Shapes.FieldDetection(this.reference.canvas, rule["@Name"], points));
          }
        }
        break;
      case "Counting":
        if (typeof rule["@Name"] === "string") {
          unpack(access(rule.PolylineArray, "Segment")).slice(0, 1).forEach(function(segment) {
            var points = unpack(segment.Point).filter(isPoint).map(toPoint);
            if (Shapes.isPolyline(points)) {
              this.reference.mShapeList.push(new Shapes.Counting(this.reference.canvas, rule["@Name"], points));
              if (this.reference.mSummary === undefined) {
                this.reference.mSummary = new Shapes.Summary();
              }
            }
          });
        }
        break;
      case "NonDetectionField":
        unpack(rule.Polygon).map(function(polygon) {
          return unpack(polygon.Point).filter(isPoint).map(toPoint);
        }).filter(Shapes.isPolygon).forEach(function(points) {
          this.reference.mExclusiveArea.push(new Shapes.ExclusiveArea(points));
        }, this);
        break;
      case "FlowPathCounting":
        if (typeof rule["@Name"] === "string") {
          var segments = unpack(access(rule.PolylineArray, "Segment")).map(function(segment) {
            return unpack(segment.Point).filter(isPoint).map(toPoint);
          });
          if (Shapes.isFlowPathCounting(segments)) {
            this.reference.mShapeList.push(new Shapes.FlowPathCounting(this.reference.canvas, rule["@Name"], segments));
            if (this.reference.mSummary === undefined) {
              this.reference.mSummary = new Shapes.Summary();
            }
          }
        }
        break;
      }
    }, this);
  }
}