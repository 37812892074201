const requestAnimFrame = (function () {
  return window.requestAnimationFrame
    || window.webkitRequestAnimationFrame
    || window.mozRequestAnimationFrame
    || window.oRequestAnimationFrame
    || window.msRequestAnimationFrame
    || function setTimeout(callback) {
         return window.setTimeout(callback, 1000 / 30);
       };
})();

const cancelAnimFrame = (function () {
  return window.cancelAnimationFrame
    || window.mozCancelAnimationFrame
    || function (timeout) {
         window.clearTimeout(timeout);
       };
})();

export {
  requestAnimFrame,
  cancelAnimFrame,
};
