import detectedBrowser from '@vivotek/lib-utility/detectedBrowser';
import { ab2str } from '@vivotek/lib-utility/convert_arraybuffer_string';

export const isIE = detectedBrowser.isIE;
export const isSafari = detectedBrowser.isSafari;
export const isFirefox = detectedBrowser.isFirefox;

export const parseBinaryToJSON = function parseBinaryToJSON(notify) {
  let res;
  let arr;

  try {
    if (isIE) {
      arr = new Uint8Array(notify.data.toArray()).slice(4);
      res = JSON.parse(ab2str(arr));
    } else {
      res = JSON.parse(ab2str(notify));
    }
  } catch (e) {
    console.warn('parse notify fail', e);
  }

  return res;
};

export const parseSdp = function parseSdp(sdpData) {
  const lines = sdpData.split('\n');
  const headers = {};
  const mediaHeaders = [];
  let statusCode;

  lines.forEach((_line, index) => {
    const line = _line.trim();

    if (index === 0) {
      [, statusCode] = line.split(' ');
      return;
    }

    if (line[1] === '=') {
      mediaHeaders.push(line.trim());
    } else if (line) {
      const split = line.split(':');
      const data = split.slice(1).join(':').trim();

      headers[split[0].trim()] = data.match(/^[0-9]+$/) ? parseInt(data, 10) : data;
    }
  });

  return {
    status: Number(statusCode),
    headers,
    mediaHeaders
  };
};

export const readUInt16BE = function readUInt16BE(bArray, index, length) {
  let val = '';
  const arrayLength = length === undefined ? 2 : length;

  for (let i = 0; i < arrayLength; i += 1) {
    val += (`0${bArray[index + i].toString(16)}`).substr(-2);
  }
  return parseInt(val, 16);
};

export const appendBuffer = function appendBuffer(buffer1, buffer2) {
  const tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
  tmp.set(new Uint8Array(buffer1), 0);
  tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
  return tmp.buffer;
};

export const checkStringInHead = function (arrayData, str) {
  return [].map.call(str, function (val) {
    return val.charCodeAt(0);
  }).every(function  (val, i) {
    return arrayData[i] === val;
  });
};

export const checkIsRTPData = function checkBinaryFirstIs(bArray, value = 0x24) {
  return bArray[0] === value && (bArray[1] >= 0 || bArray[1] < 256);
};
