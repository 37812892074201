import { unpack, access, isPointVerJSON as isPoint, toPointVerJSON as toPoint } from './utility';
import Shapes from './utils/shapes';

export default class RuleHandlerVerJSON {
  constructor(reference) {
    this.reference = reference;
  }

  // XXX: all of this handler should be move into 3rd party js for centerlized management
  handleCounting($rule) {
    unpack($rule.Counting).slice(0, 1).forEach(function(rule) {
      Object.keys(rule).forEach(function(name) {
        unpack(rule[name].Line).slice(0, 1).forEach(function(line) {
          var points = unpack(line).filter(isPoint).map(toPoint);
          if (Shapes.isPolyline(points)) {
            this.reference.mShapeList.push(new Shapes.Counting(this.reference.canvas, rule[name].RuleName, points, rule[name].PresetName));
            if (this.reference.mSummary === undefined) {
              this.reference.mSummary = new Shapes.Summary();
            }
          }
        }, this);
      }, this);
    }, this);
  }

  handleField($rule) {
    unpack(access($rule.ExclusiveArea, "Field")).map(function(field) {
      return unpack(field).filter(isPoint).map(toPoint);
    }).filter(Shapes.isPolygon).forEach(function(points) {
      this.reference.mExclusiveArea.push(new Shapes.ExclusiveArea(points));
    }, this);
  }

  handleFlowCounting($rule) {
    unpack($rule.FlowPathCounting).slice(0, 1).forEach(function(rule) {
      Object.keys(rule).forEach(function(name) {
        var segments = unpack(rule[name].Line).map(function(line) {
          return unpack(line).filter(isPoint).map(toPoint);
        });
        if (Shapes.isFlowPathCounting(segments)) {
          this.reference.mShapeList.push(new Shapes.FlowPathCounting(this.reference.canvas, rule[name].RuleName, segments, rule[name].PresetName));
          if (this.reference.mSummary === undefined) {
            this.reference.mSummary = new Shapes.Summary();
          }
        }
      }, this);
    }, this);
  }

  handleZoneDetection($rule) {
    unpack($rule.ZoneDetection).slice(0, 1).forEach(function(rule) {
      Object.keys(rule).forEach(function(name) {
        unpack(rule[name].Field).slice(0, 1).forEach(function(field) {
          var points = unpack(field).filter(isPoint).map(toPoint);
          if (Shapes.isPolygon(points)) {
            this.reference.mShapeList.push(new Shapes.FieldDetection(this.reference.canvas, rule[name].RuleName, points, rule[name].PresetName));
          }
        }, this);
      }, this);
    }, this);
  }

  handleCrowdDetection($rule) {
    unpack($rule.CrowdDetection).slice(0, 1).forEach(function(rule) {
      Object.keys(rule).forEach(function(name) {
        unpack(rule[name].Field).slice(0, 1).forEach(function(field) {
          var points = unpack(field).filter(isPoint).map(toPoint);
          if (Shapes.isPolygon(points)) {
            this.reference.mShapeList.push(new Shapes.FieldDetection(this.reference.canvas, rule[name].RuleName, points, rule[name].PresetName));
          }
        }, this);
      }, this);
    }, this);
  }

  handleFaceDetection($rule) {
    unpack($rule.FaceDetection).slice(0, 1).forEach(function(rule) {
      Object.keys(rule).forEach(function(name) {
        unpack(rule[name].Field).slice(0, 1).forEach(function(field) {
          var points = unpack(field).filter(isPoint).map(toPoint);
          if (Shapes.isPolygon(points)) {
            this.reference.mShapeList.push(new Shapes.FieldDetection(this.reference.canvas, rule[name].RuleName, points, rule[name].PresetName));
          }
        }, this);
      }, this);
    }, this);
  }

  handleIntruitionDetection($rule) {
    unpack($rule.IntrusionDetection).slice(0, 1).forEach(function(rule) {
      Object.keys(rule).forEach(function(name) {
        unpack(rule[name].Field).slice(0, 1).forEach(function(field) {
          var points = unpack(field).filter(isPoint).map(toPoint);
          if (Shapes.isPolygon(points)) {
            this.reference.mShapeList.push(new Shapes.FieldDetection(this.reference.canvas, rule[name].RuleName, points, rule[name].PresetName));
          }
        }, this);
      }, this);
    }, this);
  }

  handleMissingObjectDetection($rule) {
    unpack($rule.MissingObjectDetection).slice(0, 1).forEach(function(rule) {
      Object.keys(rule).forEach(function(name) {
        unpack(rule[name].Field).slice(0, 1).forEach(function(field) {
          var points = unpack(field).filter(isPoint).map(toPoint);
          if (Shapes.isPolygon(points)) {
            this.reference.mShapeList.push(new Shapes.FieldDetection(this.reference.canvas, rule[name].RuleName, points, rule[name].PresetName));
          }
        }, this);
      }, this);
    }, this);
  }

  handleMotionDetection($rule) {
    unpack(access($rule.MotionDetection, "Roi")).forEach(function(roi) {
      var points = unpack(roi.PolygonStd).filter(isPoint).map(toPoint);
      if (Shapes.isPolygon(points)) {
        this.reference.mShapeList.push(new Shapes.FieldDetection(this.reference.canvas, roi.Name, points, roi.PresetName));
        this.reference.mMotionDetectionAlerts = false;
      }
    }, this);
  }

  handleUnattendedObjectDetection($rule) {
    unpack($rule.UnattendedObjectDetection).slice(0, 1).forEach(function(rule) {
      Object.keys(rule).forEach(function(name) {
        unpack(rule[name].Field).slice(0, 1).forEach(function(field) {
          var points = unpack(field).filter(isPoint).map(toPoint);
          if (Shapes.isPolygon(points)) {
            this.reference.mShapeList.push(new Shapes.FieldDetection(this.reference.canvas, rule[name].RuleName, points, rule[name].PresetName));
          }
        }, this);
      }, this);
    }, this);
  }

  handleLineCrossingDetection($rule) {
    unpack($rule.LineCrossingDetection).slice(0, 1).forEach(function(rule) {
      Object.keys(rule).forEach(function(name) {
        unpack(rule[name].Line).slice(0, 1).forEach(function(line) {
          var points = unpack(line).filter(isPoint).map(toPoint);
          if (Shapes.isPolyline(points)) {
            this.reference.mShapeList.push(new Shapes.LineDetection(this.reference.canvas, rule[name].RuleName, points, rule[name].Direction, rule[name].PresetName));
          }
        }, this);
      }, this);
    }, this);
  }

  handleLoiteringDetection($rule) {
    unpack($rule.LoiteringDetection).slice(0, 1).forEach(function(rule) {
      Object.keys(rule).forEach(function(name) {
        unpack(rule[name].Field).slice(0, 1).forEach(function(field) {
          var points = unpack(field).filter(isPoint).map(toPoint);
          if (Shapes.isPolygon(points)) {
            this.reference.mShapeList.push(new Shapes.FieldDetection(this.reference.canvas, rule[name].RuleName, points, rule[name].PresetName));
          }
        }, this);
      }, this);
    }, this);
  }

  handleSmartTrackingDetection($rule) {
    unpack($rule.SmartTrackingDetection).slice(0, 1).forEach(function(rule) {
      Object.keys(rule).forEach(function(name) {
        unpack(rule[name].Field).slice(0, 1).forEach(function(field) {
          var points = unpack(field).filter(isPoint).map(toPoint);
          if (Shapes.isPolygon(points)) {
            this.reference.mShapeList.push(new Shapes.FieldDetection(this.reference.canvas, rule[name].RuleName, points, rule[name].PresetName));
          }
        }, this);
      }, this);
    }, this);
  }

  handleRunningDetection($rule) {
    unpack($rule.RunningDetection).slice(0, 1).forEach(function(rule) {
      Object.keys(rule).forEach(function(name) {
        unpack(rule[name].Field).slice(0, 1).forEach(function(field) {
          var points = unpack(field).filter(isPoint).map(toPoint);
          if (Shapes.isPolygon(points)) {
            this.reference.mShapeList.push(new Shapes.FieldDetection(this.reference.canvas, rule[name].RuleName, points, rule[name].PresetName));
          }
        }, this);
      }, this);
    }, this);
  }

  handleParkingViolationDetection($rule) {
    unpack($rule.ParkingViolationDetection).slice(0, 1).forEach(function(rule) {
      Object.keys(rule).forEach(function(name) {
        unpack(rule[name].Field).slice(0, 1).forEach(function(field) {
          var points = unpack(field).filter(isPoint).map(toPoint);
          if (Shapes.isPolygon(points)) {
            this.reference.mShapeList.push(new Shapes.FieldDetection(this.reference.canvas, rule[name].RuleName, points, rule[name].PresetName));
          }
        }, this);
      }, this);
    }, this);
  }

  handleRestrictedZoneDetection($rule) {
    unpack($rule.RestrictedZoneDetection).slice(0, 1).forEach(function(rule) {
      Object.keys(rule).forEach(function(name) {
        unpack(rule[name].DefinedZone).forEach(function(field) {
          var points = unpack(field).filter(isPoint).map(toPoint);
          if (Shapes.isPolygon(points)) {
            this.reference.mShapeList.push(new Shapes.PassingZone(this.reference.canvas, rule[name].RuleName, points, rule[name].PresetName));
          }
        }, this);
        unpack(rule[name].RestrictedZone).forEach(function(field) {
          var points = unpack(field).filter(isPoint).map(toPoint);
          if (Shapes.isPolygon(points)) {
            this.reference.mShapeList.push(new Shapes.FieldDetection(this.reference.canvas, rule[name].RuleName, points, rule[name].PresetName));
          }
        }, this);
      }, this);
    }, this);
  }

  handleExclusiveMask($rule) {
    unpack($rule.ExclusiveMask).filter(rule => rule.PresetName !== undefined).forEach(function(rule) {
      unpack(rule.Field).map(field => unpack(field).filter(isPoint).to(toPoint)).filter(Shapes.isPolygon).forEach(function(points) {
        this.reference.mShapeList.push(new Shapes.ExclusiveMask(points, rule.PresetName));
      }, this);
    }, this);
  }

  handle($rule) {
    if (!$rule) {
      return;
    }

    this.handleCounting($rule);
    this.handleField($rule);
    this.handleFlowCounting($rule);
    this.handleCrowdDetection($rule);
    this.handleFaceDetection($rule);
    this.handleIntruitionDetection($rule);
    this.handleRunningDetection($rule);
    this.handleLineCrossingDetection($rule);
    this.handleMissingObjectDetection($rule);
    this.handleMotionDetection($rule);
    this.handleExclusiveMask($rule);
    this.handleParkingViolationDetection($rule);
    this.handleRestrictedZoneDetection($rule);
    this.handleSmartTrackingDetection($rule);
    this.handleUnattendedObjectDetection($rule);
    this.handleZoneDetection($rule);
  }
}
