import Shapes from './utils/shapes';

function access(obj) {
  for (var i = 1, j = arguments.length; i < j && obj !== undefined; i++) {
    obj = obj[arguments[i]];
  }
  return obj;
}

var isPoint = function(obj) {
  return !isNaN(obj["@x"]) && !isNaN(obj["@y"]);
};

var toPoint = function(obj) {
  return new Shapes.Point(obj["@x"] / 320, obj["@y"] / 208);
};

var isPointVerJSON = function(obj) {
  return !isNaN(obj.x) && !isNaN(obj.y);
};

var toPointVerJSON = function(obj) {
  return new Shapes.Point(obj.x / 10000, obj.y / 10000);
};

var fromSimpleItem = function(result, ttSimpleItem) {
  if (typeof ttSimpleItem["@Name"] === "string") {
    result[ttSimpleItem["@Name"]] = ttSimpleItem["@Value"];
  }
  return result;
};

var isRuleName = function(ttSimpleItem) {
  return ttSimpleItem["@Name"] === "Rule";
};

var isObjectId = function(ttSimpleItem) {
  return ttSimpleItem["@Name"] === "ObjectId";
};

var isNumber = function(prop) {
  return !isNaN(access(this, prop));
};

function unpack(obj) {
  var arr = [];
  if (obj !== undefined) {
    arr = arr.concat(obj);
  }
  return arr;
}

var isInsideThePolygon = function(e, t) {
  for (var n = !1, a = e.x, i = e.y, s = t.length, o = t, r = 0, l = s - 1; r < s; l = r, r += 1) o[r].y > i != o[l].y > i && a < (o[l].x - o[r].x) * (i - o[r].y) / (o[l].y - o[r].y) + o[r].x && (n = !n);
  return n
};

let requestPaint = () => {};

/**
 * PackBits unpack function
 *
 * @param {String} data
 * @return {String}
 */
function unpackBits(data) {
  var output = '',
  i = 0;

  while (i < data.length) {
    var hex = data.charCodeAt(i);

    if (hex >= 128) {
      hex = 256 - hex;

      for (var j = 0; j <= hex; j ++) {
        output += data.charAt(i + 1);
      }

      i ++;
    }
    else {
      for (var j = 0; j <= hex; j ++) {
        output += data.charAt(i + j + 1);
      }

      i += j;
    }

    i ++;
  }

  return output;
}

function base64Decode(input) {
  var _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
  var output = "";
  var chr1, chr2, chr3;
  var enc1, enc2, enc3, enc4;
  var i = 0;
  while (i < input.length) {
    enc1 = _keyStr.indexOf(input.charAt(i++));
    enc2 = _keyStr.indexOf(input.charAt(i++));
    enc3 = _keyStr.indexOf(input.charAt(i++));
    enc4 = _keyStr.indexOf(input.charAt(i++));
    chr1 = enc1 << 2 | enc2 >> 4;
    chr2 = (enc2 & 15) << 4 | enc3 >> 2;
    chr3 = (enc3 & 3) << 6 | enc4;
    output = output + String.fromCharCode(chr1);
    if (enc3 != 64) {
      output = output + String.fromCharCode(chr2);
    }
    if (enc4 != 64) {
      output = output + String.fromCharCode(chr3);
    }
  }
  return output;
}

export {
  access,
  unpack,
  isPoint,
  toPoint,
  fromSimpleItem,
  isNumber,
  isRuleName,
  isObjectId,
  requestPaint,
  isInsideThePolygon,
  unpackBits,
  base64Decode,
  isPointVerJSON,
  toPointVerJSON,
}
