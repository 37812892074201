import { unpack, access, isNumber, requestPaint, unpackBits, base64Decode } from './utility';
import Shapes from './utils/shapes';

export default class MetadataHandlerVerJSON {
  constructor(reference) {
    this.reference = reference;
  }

  createProjectionIfNecessary($metadata) {
    if (this.reference.vcaProject) {
      return;
    }
    var p = $metadata.Project;
    if (["CamH", "Cx", "Cy", "H", "Offsetx", "Offsety", "W", "f"].every(isNumber.bind(p))) {
      this.reference.mProject = new Shapes.Project({ Cx: p.Cx, Cy: p.Cy, CamHeight: p.CamH, TiltAngle: isNumber.call(p, "Tilt") ? p.Tilt : 0, RollAngle: p.Roll, FocalLength: p.f, OffsetX: p.Offsetx, OffsetY: p.Offsety, ResolutionW: p.W, ResolutionH: p.H });
    } else if (["fx", "fy", "cx", "cy", "k1", "k2", "k3", "k4", "IsFishEye", "ORGWidth", "ORGHeight", "CamHeight", "TiltAngle"].every(isNumber.bind(p))) {
      this.reference.mProject = new Shapes.Project({
        M1: [[p.fx, 0, p.cx],
          [0, p.fy, p.cy],
          [0, 0, 1]],
        D1: [p.k1, p.k2, p.k3, p.k4,
          Number([p.k5]), Number([p.k6]), Number([p.k7]), Number([p.k8]),
          Number([p.k9]), Number([p.k10]), Number([p.k11]), Number([p.k12])],
        CamHeight: p.CamHeight,
        TiltAngle: p.TiltAngle,
        RollAngle: p.RollAngle,
        ORGHeight: p.ORGHeight,
        ORGWidth: p.ORGWidth,
        IsFishEye: p.IsFishEye,
        IsCylindStitch: p.IsCylindStitch,
        CylindStitch: p.CylindStitch
      });
    }
  }

  handleProjection($metadata) {
    if (this.reference.mProject !== undefined) {
      unpack($metadata[this.reference.isStitch ? "Stitch" : "Frame"]).slice(0, 1).forEach(function(f) {
        var objects = unpack(f.Objects).filter(function(o) {
          return unpack(o.Behaviour).every(function(b) {
            return b !== "Missing" && b !== "Unattended";
          });
        });
        var rectangles = objects.filter(function(o) {
          if (o.Type === "Vehicle") {
            return unpack(o.Pos2D).length >= 3;
          }
          return ["x", "y"].every(isNumber.bind(o.Centroid)) && ["Height", "Id"].every(isNumber.bind(o)) && ["x", "y"].every(isNumber.bind(o.Origin));
        }).map(function(o) {
          if (o.Type === "Vehicle") {
            return new Shapes.BoundingBox(o.Id, unpack(o.Pos2D).map(pt => new Shapes.Point(pt.x / 10000, pt.y / 10000).scale(this.reference.objScaleX, this.reference.objScaleY).translate(this.reference.objTranslation)), "#8835FD");
          }
          return new Shapes.BoundingBox3D(
            typeof o["GId"] === "number" ? o.GId : o.Id,
            o.Height,
            this.reference.mProject.ProjectPoint({ x: o.Origin.x, y: o.Origin.y, z: 0 }).scale(this.reference.objScaleX, this.reference.objScaleY).translate(this.reference.objTranslation),
            this.reference.mProject.ProjectPoint({ x: o.Centroid.x, y: o.Centroid.y, z: this.reference.isStitch ? 0 : o.Height * 2 / 3 }).scale(this.reference.objScaleX, this.reference.objScaleY).translate(this.reference.objTranslation),
            this.reference.mProject.Get2DBoundingBox([
              { x: o.Centroid.x - 180, y: o.Centroid.y - 180, z: 0 },
              { x: o.Centroid.x + 180, y: o.Centroid.y - 180, z: 0 },
              { x: o.Centroid.x + 180, y: o.Centroid.y + 180, z: 0 },
              { x: o.Centroid.x - 180, y: o.Centroid.y + 180, z: 0 },
              { x: o.Centroid.x - 180, y: o.Centroid.y - 180, z: o.Height },
              { x: o.Centroid.x + 180, y: o.Centroid.y - 180, z: o.Height },
              { x: o.Centroid.x + 180, y: o.Centroid.y + 180, z: o.Height },
              { x: o.Centroid.x - 180, y: o.Centroid.y + 180, z: o.Height }
            ]).map(pt => pt.scale(this.reference.objScaleX, this.reference.objScaleY).translate(this.reference.objTranslation)));
        }, this);
        var faces = objects.filter(function(o) {
          return ["h", "w", "x", "y"].every(isNumber.bind(access(o.Face, "Rectangle"))) && isNumber.call(o, "Id");
        }).map(function(o) {
          return new Shapes.Face(o.Id, o.Face.Rectangle);
        });
        if (faces.length) {
          rectangles = rectangles.concat(faces);
        }
        this.reference.updateMetjRectangles(rectangles);
      }, this);
    } else {
      var objects = unpack(access($metadata.Frame, "Objects")).filter(o => unpack(o.Behaviour).every(b => b !== "Missing" && b !== "Unattended"));
      var rectangles = objects.filter(o => unpack(o.Pos2D).length >= 3).map(o => new Shapes.BoundingBox(o.Id, unpack(o.Pos2D).map(pt => new Shapes.Point(pt.x / 10000, pt.y / 10000).scale(this.reference.objScaleX, this.reference.objScaleY).translate(this.reference.objTranslation)), o.Type === "Vehicle" ? "#8835FD" : "#2660b7"));
      rectangles.concat(objects.filter(o => ["h", "w", "x", "y"].every(isNumber.bind(access(o.Face, "Rectangle"))) && isNumber.call(o, "Id")).map(o => new Shapes.Face(o.Id, o.Face.Rectangle)));
      this.reference.updateMetjRectangles(rectangles);
    }
  }

  handleCellMotion($metadata) {
    unpack($metadata[this.reference.isStitch ? "Stitch" : "Frame"]).slice(0, 1).forEach(f => {
      if (f.CellMotion && f.CellMotion.Layout && (f.CellMotion.Layout.Width || 0) && (f.CellMotion.Layout.Height || 0)) {
        if (this.reference.mCellMotion === undefined || this.reference.mCellMotion.ActiveCells !== f.CellMotion.ActiveCells) {
          this.reference.mCellMotion = f.CellMotion;
          this.reference.mCellMotion.Cells = unpackBits(base64Decode(f.CellMotion.ActiveCells));
          requestPaint();
        }
      } else {
        if (this.reference.mCellMotion !== undefined) {
          this.reference.mCellMotion = undefined;
          requestPaint();
        }
      }
    });
  }

  handleMetaData($metadata) {
    this.createProjectionIfNecessary($metadata);
    this.handleProjection($metadata);
    this.handleCellMotion($metadata);
    this.reference.updateMissingObjects(unpack(access($metadata.Frame, "Objects")).filter(function(o) {
      return unpack(o.Behaviour).some(function(b) {
        return b === "Missing" || b === "Unattended";
      }) && unpack(o.Pos2D).length >= 3;
    }).map(function(o) {
      return unpack(o.Pos2D).map(function(pt) {
        return new Shapes.Point(pt.x / 10000, pt.y / 10000);
      });
    }));
  }

  handleCounting(data) {
    unpack(data.CountingInfo).forEach(function(countingInfo) {
      this.reference.accumulative(countingInfo.RuleName, countingInfo.In, countingInfo.Out);
    }, this);
  }

  handleBehaviorAlarm(data) {
    if (!this.reference.showSummary) {
      return;
    }
    unpack(data.BehaviorAlarmInfo).forEach(function(behaviorAlarmInfo) {
      for (var i = 0, j = this.reference.mShapeList.length; i < j; i++) {
        if (this.reference.mShapeList[i].name === behaviorAlarmInfo.RuleName) {
          this.reference.mShapeList[i].behaviorAlarm();
          if (this.reference.mProject !== undefined) {
            this.reference.mShapeList[i].objectDetected(unpack(behaviorAlarmInfo.Objects).filter(function(obj) {
              return (obj.Type === "Missing" || obj.Type === "Abandon") && unpack(obj.Polygon).length >= 3;
            }).map(function(obj) {
              return unpack(obj.Polygon).map(function(pt) {
                return this.reference.mProject.ProjectPoint({ x: pt.x, y: pt.y, z: 0 });
              });
            }, this));
          }
        }
      }
    }, this);
  }

  handleCellMotionInfo(data) {
    if (!this.reference.showSummary) {
      return;
    }
    unpack(data.CellMotionInfo).filter(function(cellMotionInfo) {
      return cellMotionInfo.Status == 1;
    }).forEach(function(cellMotionInfo) {
      for (var i = 0, j = this.reference.mShapeList.length; i < j; i++) {
        if (this.reference.mShapeList[i].name === cellMotionInfo.Name) {
          this.reference.mShapeList[i].behaviorAlarm();
        }
      }
    }, this);
  }

  handleZoneDetection(data) {
    if (!this.reference.showSummary) {
      return;
    }
    unpack(data.ZoneInfo).forEach(function(zoneInfo) {
      for (var i = 0, j = this.reference.mShapeList.length; i < j; i++) {
        if (this.reference.mShapeList[i].name === zoneInfo.RuleName) {
          this.reference.mShapeList[i].behaviorAlarm();
        }
      }
    }, this);
  }

  handleBehaviorAlarmInfo(data) {
    if (!this.reference.showSummary) {
      return;
    }
    unpack(data.BehaviorAlarmInfo).forEach(function(behaviorAlarmInfo) {
      if (behaviorAlarmInfo.RuleType === "RunningDetection" && behaviorAlarmInfo.EdgeEvent === "Falling")
        return;
      for (var i = 0, j = this.reference.mShapeList.length; i < j; i++) {
        if (this.reference.mShapeList[i].name === behaviorAlarmInfo.RuleName) {
          this.reference.mShapeList[i].behaviorAlarm();
          if (behaviorAlarmInfo.RuleType === "MissingObjectDetection" ||
            behaviorAlarmInfo.RuleType === "UnattendedObjectDetection") {
            if (this.reference.mProject !== undefined) {
              this.reference.mShapeList[i].objectDetected(unpack(behaviorAlarmInfo.Objects).filter(function(obj) {
                return unpack(obj.Polygon).length >= 3;
              }).map(function(obj) {
                return unpack(obj.Polygon).map(function(pt) {
                  return this.reference.mProject.ProjectPoint({ x: pt.x, y: pt.y, z: 0 });
                });
              }, this));
            }
          }
        }
      }
    }, this);
  }

  handleEvent($metadata) {
    unpack($metadata.Data).forEach(function(data) {
      switch (data.RuleType) {
        case "Counting":
          this.handleCounting(data);
          break;
        case "BehaviorAlarm":
          this.handleBehaviorAlarm(data);
          break;
        case "CellMotion":
          this.handleCellMotionInfo(data);
          break;
        case "ZoneDetection":
          this.handleZoneDetection(data);
          break;
        default:
          this.handleBehaviorAlarmInfo(data);
      }
    }, this);
  }

  handleStatus($metadata) {
    var preset = access($metadata.PTZInfoADV, "Preset", "Current");
    if (preset !== undefined && this.reference.mCurrentPreset !== preset) {
      this.reference.mCurrentPreset = preset;
      if (!this.reference.mIsPTZInfoTracking) {
        requestPaint();
      }
    }
    switch (access($metadata.PTZInfo, "Status")) {
      case "Sleep":
      case "Tracking":
        if (!this.reference.mIsPTZInfoTracking) {
          this.reference.mIsPTZInfoTracking = true;
          if (this.reference.shouldPaintForPreset) {
            requestPaint();
          }
        }
        break;
      case "Waiting":
        if (this.reference.mIsPTZInfoTracking) {
          this.reference.mIsPTZInfoTracking = false;
          if (this.reference.shouldPaintForPreset) {
            requestPaint();
          }
          if (this.reference.mCellMotion !== undefined) {
            this.reference.mCellMotion = undefined;
            requestPaint();
          }
        }
        break;
      }
  }

  handle($metadata) {
    switch ($metadata.Tag) {
      case "MetaData":
        this.handleMetaData($metadata);
        break;
      case "Event":
        this.handleEvent($metadata);
        break;
      case "Status":
        this.handleStatus($metadata);
        break;
      default:
        break;
    }
  }
}