import { unpack, access, isPoint, toPoint, fromSimpleItem, isRuleName, isObjectId, isNumber } from './utility';
import Shapes from './utils/shapes';

export default class MetadataHandler {
  constructor(reference, ) {
    this.reference = reference;
  }

  handleVideoAnalytics(ttMetaDataStream) {
    unpack(access(ttMetaDataStream, "tt:VideoAnalytics", "tt:Frame")).slice(0, 1).forEach(function(ttFrame) {
      var rectangles = [];
      var ttScale = unpack(access(ttFrame["tt:Transformation"], "tt:Scale")).slice(0, 1).filter(isPoint).pop();
      unpack(ttFrame["tt:Object"]).filter(function(ttObject) {
        return !isNaN(ttObject["@ObjectId"]);
      }).forEach(function(ttObject) {
        var ttShape = access(ttObject["tt:Appearance"], "tt:Shape");
        var ttExtension = access(ttShape, "tt:Extension");
        var ttCoordinate = access(ttExtension, "tt:RectifiedImageCoordinate");
        if (ttCoordinate !== undefined) {
          var ttHeight = ttExtension["tt:Height"];
          var ttOrigin = ttCoordinate["tt:Origin"];
          var ttPeak = ttCoordinate["tt:Peak"];
          if (!isNaN(ttHeight) && ttOrigin !== undefined && isPoint(ttOrigin) && ttPeak !== undefined && isPoint(ttPeak)) {
            var points = unpack(access(ttCoordinate["tt:BoundingBox3D"], "tt:Point")).filter(isPoint).map(toPoint);
            if (points.length == 8) {
              rectangles.push(new Shapes.BoundingBox3D(
                Number(ttObject["@ObjectId"]),
                Number(ttHeight),
                toPoint(ttOrigin),
                toPoint(ttPeak),
                points));
            }
          }
        } else if (ttScale !== undefined) {
          var scaleX = Math.abs(parseFloat(ttScale["@x"]));
          var scaleY = Math.abs(parseFloat(ttScale["@y"]));
          var ttBoundingBox = access(ttShape, "tt:BoundingBox");
          if (["@left", "@top", "@right", "@bottom"].every(function(elem) {
              return !isNaN(access(ttBoundingBox, elem));
            })) {
            var objectId = Number(ttObject["@ObjectId"]);
            rectangles.push(new Shapes.BoundingBox(
              objectId,
              [
                new Shapes.Point(ttBoundingBox["@left"] * scaleX, ttBoundingBox["@top"] * scaleY),
                new Shapes.Point(ttBoundingBox["@right"] * scaleX, ttBoundingBox["@top"] * scaleY),
                new Shapes.Point(ttBoundingBox["@right"] * scaleX, ttBoundingBox["@bottom"] * scaleY),
                new Shapes.Point(ttBoundingBox["@left"] * scaleX, ttBoundingBox["@bottom"] * scaleY)
              ],
              Shapes.getColorById(objectId)));
          }
        }
      });
      this.reference.updateRectangles(rectangles);
    });
  }

  handleNotification(ttMetaDataStream) {
    unpack(access(ttMetaDataStream, "tt:Event", "wsnt:NotificationMessage")).forEach(function(wsntNotificationMessage) {
      var ttMessage = access(wsntNotificationMessage["wsnt:Message"], "tt:Message");
      if (ttMessage !== undefined) {
        switch (access(wsntNotificationMessage["wsnt:Topic"], "#text"))
        {
        case "tns1:RuleEngine/FieldDetector/VVTK_ObjectsInside":
          var ttData = unpack(access(ttMessage["tt:Data"], "tt:SimpleItem")).reduce(fromSimpleItem, {});
          if (ttData.IsInside === "true") {
            unpack(access(ttMessage["tt:Source"], "tt:SimpleItem")).filter(isRuleName).forEach(function(ttSimpleItem) {
              var ruleName = ttSimpleItem["@Value"];
              for (var i = 0, j = this.reference.mShapeList.length; i < j; i++) {
                if (this.reference.mShapeList[i].name === ruleName) {
                  this.reference.mShapeList[i].objectIsInside();
                }
              }
            }, this);
          }
          break;
        case "tns1:RuleEngine/LineDetector/VVTK_Crossed":
          unpack(access(wsntNotificationMessage["wsnt:Message"], "tt:Message", "tt:Source", "tt:SimpleItem")).filter(isRuleName).forEach(function(ttSimpleItem) {
            var ruleName = ttSimpleItem["@Value"];
            for (var i = 0, j = this.reference.mShapeList.length; i < j; i++) {
              if (this.reference.mShapeList[i].name === ruleName) {
                this.reference.mShapeList[i].crossed();
              }
            }
          }, this);
          break;
        case "tns1:RuleEngine/CountAggregation/VVTK_Accumulative":
          var ttData = unpack(access(ttMessage["tt:Data"], "tt:SimpleItem")).reduce(fromSimpleItem, {});
          unpack(access(ttMessage["tt:Source"], "tt:SimpleItem")).filter(isRuleName).forEach(function(ttSimpleItem) {
            this.reference.accumulative(ttSimpleItem["@Value"], ttData.In, ttData.Out);
          });
          unpack(access(ttMessage["tt:Key"], "tt:SimpleItem")).filter(isRuleName).forEach(function(ttSimpleItem) {
            this.reference.accumulative(ttSimpleItem["@Value"], ttData.In, ttData.Out);
          });
          break;
        case "tns1:RuleEngine/LoiteringDetector/VVTK_ObjectIsLoitering":
          var propertyOperation = ttMessage["@PropertyOperation"];
          if (propertyOperation === "Deleted") {
            unpack(access(ttMessage["tt:Key"], "tt:SimpleItem")).filter(isObjectId).forEach(function(ttSimpleItem) {
              var objectId = Number(ttSimpleItem["@Value"]);
              this.reference.mLoiterings = this.reference.mLoiterings.filter(function(loitering) {
                return loitering === objectId;
              });
            }, this);
            this.reference.updateLoitering();
          } else {
            unpack(access(ttMessage["tt:Source"], "tt:SimpleItem")).filter(isRuleName).forEach(function(ttSimpleItem) {
              var ruleName = ttSimpleItem["@Value"];
              if (ruleName !== "Fake") {
                for (var i = 0, j = this.reference.mShapeList.length; i < j; i++) {
                  if (this.reference.mShapeList[i].name === ruleName) {
                    this.reference.mShapeList[i].objectIsLoitering();
                  }
                }
              }
            }, this);
            if (propertyOperation === "Initialized") {
              unpack(access(ttMessage["tt:Key"], "tt:SimpleItem")).filter(isObjectId).forEach(function(ttSimpleItem) {
                this.reference.mLoiterings.push(Number(ttSimpleItem["@Value"]));
              }, this);
              this.reference.updateLoitering();
            }
          }
          break;
        case "tns1:VideoAnalytics/tnsvca:CrowdAlarm":
          unpack(access(ttMessage["tt:Data"], "tt:ElementItem", "ivs:DetectAreaReference")).forEach(function(ivsDetectAreaReference) {
            var ruleName = ivsDetectAreaReference["#text"];
            for (var i = 0, j = this.reference.mShapeList.length; i < j; i++) {
              if (this.reference.mShapeList[i].name === ruleName) {
                this.reference.mShapeList[i].crowdAlarm();
              }
            }
          }, this);
          break;
        }
      }
    });
  }

  handleProjectionIfNecessary(ttMetaDataStream) {
    if (!this.reference.vcaProject) {
      var p = access(ttMetaDataStream, "tt:VideoAnalytics", "Project");
      if (["@fx", "@fy", "@cx", "@cy", "@k1", "@k2", "@k3", "@k4", "@IsFishEye", "@ORGWidth", "@ORGHeight", "@CamHeight", "@TiltAngle"].every(isNumber.bind(p))) {
        this.reference.mProject = new Shapes.Project({
          M1: [[Number(p["@fx"]), 0, Number(p["@cx"])],
            [0, Number(p["@fy"]), Number(p["@cy"])],
            [0, 0, 1]],
          D1: [Number(p["@k1"]), Number(p["@k2"]), Number(p["@k3"]), Number(p["@k4"]),
            Number([p["@k5"]]), Number([p["@k6"]]), Number([p["@k7"]]), Number([p["@k8"]]),
            Number([p["@k9"]]), Number([p["@k10"]]), Number([p["@k11"]]), Number([p["@k12"]])],
          CamHeight: Number(p["@CamHeight"]),
          TiltAngle: Number(p["@TiltAngle"]),
          RollAngle: Number([p["@RollAngle"]]),
          ORGHeight: Number(p["@ORGHeight"]),
          ORGWidth: Number(p["@ORGWidth"]),
          IsFishEye: Number(p["@IsFishEye"])
        });
      }
    }

    if (this.reference.mProject !== undefined) {
      unpack(access(ttMetaDataStream, "tt:VideoAnalytics", "Frame")).slice(0, 1).forEach(function(f) {
        var rectangles = unpack(f.Object).filter(function(o) {
          return ["@x", "@y"].every(isNumber.bind(o.Centroid)) && ["@Height", "@Id"].every(isNumber.bind(o)) && ["@x", "@y"].every(isNumber.bind(o.Origin));
        }).map(function(o) {
          return new Shapes.BoundingBox3D(
            Number(!isNaN(o["@GId"]) ? o["@GId"] : o["@Id"]),
            Number(o["@Height"]),
            this.reference.mProject.ProjectPoint({ x: Number(o.Origin["@x"]), y: Number(o.Origin["@y"]), z: 0 }),
            this.reference.mProject.ProjectPoint({ x: Number(o.Centroid["@x"]), y: Number(o.Centroid["@y"]), z: Number(o["@Height"]) * 2 / 3 }),
            [
              this.reference.mProject.ProjectPoint({ x: o.Centroid["@x"] - 180, y: o.Centroid["@y"] - 180, z: 0 }),
              this.reference.mProject.ProjectPoint({ x: Number(o.Centroid["@x"]) + 180, y: o.Centroid["@y"] - 180, z: 0 }),
              this.reference.mProject.ProjectPoint({ x: Number(o.Centroid["@x"]) + 180, y: Number(o.Centroid["@y"]) + 180, z: 0 }),
              this.reference.mProject.ProjectPoint({ x: o.Centroid["@x"] - 180, y: Number(o.Centroid["@y"]) + 180, z: 0 }),
              this.reference.mProject.ProjectPoint({ x: o.Centroid["@x"] - 180, y: o.Centroid["@y"] - 180, z: Number(o["@Height"]) }),
              this.reference.mProject.ProjectPoint({ x: Number(o.Centroid["@x"]) + 180, y: o.Centroid["@y"] - 180, z: Number(o["@Height"]) }),
              this.reference.mProject.ProjectPoint({ x: Number(o.Centroid["@x"]) + 180, y: Number(o.Centroid["@y"]) + 180, z: Number(o["@Height"]) }),
              this.reference.mProject.ProjectPoint({ x: o.Centroid["@x"] - 180, y: Number(o.Centroid["@y"]) + 180, z: Number(o["@Height"]) })
            ]);
        }, this);
        this.reference.updateRectangles(rectangles);
      });
    }
  }

  handleEvent(ttMetaDataStream) {
    var ttEvent = access(ttMetaDataStream, "tt:Event");
    if (ttEvent !== undefined) {
      for (var prop in ttEvent) {
        switch (prop)
        {
        case "Counting":
          unpack(ttEvent["Counting"]).forEach(function(counting) {
            this.refernece.accumulative(counting["@RuleName"], counting["@In"], counting["@Out"]);
          }, this);
          break;
        }
      }
    }
  }

  handle($metadata) {
    var ttMetaDataStream = $metadata["tt:MetadataStream"] || $metadata["tt:MetaDataStream"];

    if (this.reference.deleteVideoAnalytics) {
      delete ttMetaDataStream["tt:VideoAnalytics"];
    }

    this.handleVideoAnalytics(ttMetaDataStream);

    this.handleNotification(ttMetaDataStream);

    this.handleProjectionIfNecessary(ttMetaDataStream);

    this.handleEvent(ttMetaDataStream);
  }
}